body {
   -webkit-user-select: none;
  background-color: black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fb-like {
  width: 100%;
  margin-top: 10px;
  font-size: .5em;
  overflow: hidden;
}

#sharebutton {
  color: #2c3e50;
  line-height: 1em;
  color: inherit;
  text-decoration: none;
  border-radius: 4px;
  background-color: chartreuse;
  box-shadow: 0 0 0 0 #2c3e50 inset, 0.3em 0.2em 0 0 #bbb;
  border: 0.2em solid #2c3e50;
  padding: 0.8em;
  text-align: center;
  transition: 0.25s box-shadow, 0.25s transform;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

#movieheader {
  font-family: "Orbitron", sans-serif;
}

#disclosure {
  font-size: 1em;
  padding-top: 20px;
}

#tmdblogo {
  width: 35%;
  padding: 1%
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#mobilemodal {
  display: none;
}

#answerlist {
  list-style-type: none;
  cursor: pointer;
  font-family: "Bebas Neue", cursive;
  padding: 0px 10px 0px 10px;
  margin: 0px;
}

  .mylistitem {
  font-size: 1.8rem;
    line-height: 1.6rem;
    margin: 1.5rem;
}

.mylistitem:hover {
  color: red;
  font-size: 2.5rem;
  line-height: 2rem;
}

#scorebox {
  width: 111pt;
  height: auto;
  position: absolute;
  right: 50px;
  top: 10px;
  text-align: left;
  padding: 10px;
  color: chartreuse;
  font-family: "Bebas Neue", cursive;
}

#sharebox {
  color: black;
}

#leaderboard {
  font-family: "Bebas Neue", cursive;
  height: 300px;
  text-align: left;
  width: fit-content;
  position: absolute;
  padding: 10px;
  left: 10px;
  top: 10px;
}

#leaderheader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row;

}

#leaderheader div {
  padding-right: 10px;
  padding-left: 3px;
}

#leaderscroll {
  height: 300px;
  overflow-y: auto;
}

#leaderscss {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row;
  color: blueviolet;
  font-size: 15pt;
  justify-content: space-evenly;
}

#leaderscss div {
  padding-right: 10px;
  padding-left: 3px;
}

#movietitle {
margin-bottom: 35px;
  display: block;
}

.grid-item {
  align-self: center;
}

#movieimage {
  flex: 1;
  width: 200px;
  height: 200px;
  resizemode: "contain";
border: 20px solid yellow;
}

#userwins,
#userloss {
  padding: 0px 10px 0px 10px;
}

#modalclosebutton {
  position: absolute;
  top: 5px;
}

#scoreheader {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

@media (max-width: 1064px) {
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-bottom: 30px;
  }
  #recordscore {
    display: none;
  }

  #leaderboard {
    position: relative;
    height: 100%;
    align-content: center;
    padding-bottom: 59px;
    padding: initial;
    margin-bottom: 31px;
  }

  .mylistitem {
  font-size: 1.8rem;
    line-height: 1.6rem;
    margin: 1.3rem;
}

    .mylistitem:hover {
      color: white;
  font-size: 1.8rem;
      line-height: 1.6rem;
}

  #answerlist {
  padding: 0px 10px 0px 10px;
  margin: 0px;
}

  #leaderheader {
 text-align: center;
}

#leaderheader div {
  text-align: center;
}

#leaderscss {
    text-align: center;
  justify-content: center;
}

#leaderscss div {
    text-align: center;
  justify-content: center;
}


  #scorebox {
    position: fixed;
    right: 5px;
    top: 35px;
    text-align: right;
  }

  #scoreheader {
    display: block;
    margin-top: initial;
    margin-bottom: initial;
    font-size: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }



  #mobilemodal {
    position: fixed;
    top: 35px;
    left: 5px;
    display:inline-block;
    margin: 10px;
    color:chartreuse;
    border:1px solid #CCC;
    background: black;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
    cursor:pointer;
    vertical-align:middle;
    max-width: 100px;
    padding: 2px;
    text-align: center;
}

#mobilemodal:active {
    color:chartreuse;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
  }
}
